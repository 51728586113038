/* eslint-disable react/prop-types */
import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from './src/tokens/theme';

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
);

export const onInitialClientRender = () => {
  // Check CookieConsent component
  // eslint-disable-next-line func-names
  window.addEventListener('load', function() {
    // eslint-disable-next-line no-unused-expressions
    window.cookieconsent &&
      window.cookieconsent.initialise({
        palette: {
          popup: {
            background: '#f4f4f4',
            text: '#786b61'
          },
          button: {
            background: '#DC1E35',
            text: '#ffffff'
          }
        },
        theme: 'classic',
        content: {
          message:
            'Този сайт използва „бисквитки“, за да бъде по-функционален и ефективен. Продължавайки да ползвате този уебсайт, Вие се съгласявате с нашата <a href="/privacy-policy">Политика за поверителност</a> и <a href="/privacy-policy">Общи условия</a> за използване на интернет страницата.',
          dismiss: 'Съгласен съм',
          link: 'Научете повече'
        }
      });
  });
};
