import {
  Flex,
  Box,
  Text,
  Container,
  FlexContainer
} from './src/components/elements';

import {
  Hero,
  Howto,
  Benefits,
  Features,
  Embed,
  CTA,
  Image,
  TextBlock,
  Text as TextSlice,
  PurchaseForm,
  Stores,
  Partners
} from './src/components/slices';

export { default as Layout } from './src/components/Layout';
export { default as colors } from './src/tokens/colors';
export { Flex, Box, Text, Container, FlexContainer };
export {
  Hero,
  Howto,
  Benefits,
  Features,
  Embed,
  CTA,
  Image,
  TextBlock,
  TextSlice,
  PurchaseForm,
  Stores,
  Partners
};
export { default as PageSlices } from './src/components/PageSlices.js';
