import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

import { FlexContainer } from '../elements';

import { linkResolver } from '../../utils/linkResolver';
import htmlSerializer from '../../utils/htmlSerializer';

const Text = ({ slice }) => {
  const { content } = slice.primary;
  return (
    <FlexContainer
      as="section"
      maxWidth="1150px"
      py={3}
      mx={[3, 'auto']}
      justifyContent="center"
      flexDirection="column"
    >
      {RichText.render(content, linkResolver, htmlSerializer)}
    </FlexContainer>
  );
};

Text.propTypes = {
  slice: PropTypes.object.isRequired
};

export default Text;
