import React from 'react';
import PropTypes from 'prop-types';
import NonStretchedImage from '../NonStretchedImage';
import { FlexContainer, Box, Text } from '../elements';
import ActionLink from '../ActionLink';

const Howto = ({ slice }) => {
  const {
    action_label: actionLabel,
    action_url: actionURL,
    heading,
    imageSharp: image
  } = slice.primary;
  return (
    <FlexContainer
      as="section"
      maxWidth="1150px"
      py={7}
      mx={[3, 'auto']}
      alignItems="center"
      flexDirection="column"
    >
      <Text as="h1" textAlign={['center', 'left']}>
        {heading}
      </Text>
      <Box width={[1, 800]} mx={3}>
        <NonStretchedImage fluid={image.childImageSharp.fluid} />
      </Box>
      <ActionLink label={actionLabel} linkURL={actionURL} color="colors.text" />
    </FlexContainer>
  );
};

Howto.propTypes = {
  slice: PropTypes.object.isRequired
};

export default Howto;
