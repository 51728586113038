import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

import { FlexContainer } from '../elements';

import { linkResolver } from '../../utils/linkResolver';
import htmlSerializer from '../../utils/htmlSerializer';

const TextBlock = ({ slice }) => {
  const { paragraph } = slice.primary;
  return (
    <FlexContainer
      as="section"
      maxWidth="1150px"
      py={3}
      mx={[3, 'auto']}
      justifyContent="center"
    >
      {RichText.render(paragraph, linkResolver, htmlSerializer)}
    </FlexContainer>
  );
};

TextBlock.propTypes = {
  slice: PropTypes.object.isRequired
};

export default TextBlock;
