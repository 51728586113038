import React from 'react';
import PropTypes from 'prop-types';
import { FlexContainer, Box, Text } from '../elements';
import ButtonLink from '../ButtonLink';

const CallToAction = ({ slice }) => {
  const {
    heading,
    subheading,
    action_url: actionURL,
    action_label: actionLabel
  } = slice.primary;
  const patternIsExternal = /^((http|https|ftp):\/\/)/;
  const isExternalURL = patternIsExternal.test(actionURL);
  return (
    <FlexContainer
      as="section"
      flexDirection="column"
      alignItems="center"
      maxWidth="1150px"
      px={[0, 9]}
      py={[9]}
      mx={[3, 'auto']}
    >
      <Text as="h1" textAlign={['center', 'left']}>
        {heading}
      </Text>
      <Text py={5} textAlign="center" lineHeight={2}>
        {subheading}
      </Text>
      <Box py={3}>
        {isExternalURL ? (
          <ButtonLink as="a" href={actionURL}>
            {actionLabel}
          </ButtonLink>
        ) : (
          <ButtonLink to={actionURL}>{actionLabel}</ButtonLink>
        )}
      </Box>
    </FlexContainer>
  );
};

CallToAction.propTypes = {
  slice: PropTypes.object.isRequired
};

export default CallToAction;
