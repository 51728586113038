import Loadable from '@loadable/component';

import Hero from './Hero';
import Howto from './Howto';
import Benefits from './Benefits';
import Features from './Features';
import Embed from './Embed';
import CTA from './CTA';
import Image from './Image';
import TextBlock from './TextBlock';
import Text from './Text';
import PurchaseForm from './PurchaseForm';
import Partners from './Partners';

const LoadableStores = Loadable(() => import('./Stores'));

export {
  Hero,
  Howto,
  Benefits,
  Features,
  Embed,
  CTA,
  Image,
  TextBlock,
  Text,
  PurchaseForm,
  LoadableStores as Stores,
  Partners
};
