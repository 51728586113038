import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { RichText } from 'prismic-reactjs';
import { FlexContainer, Flex, Box } from '../elements';
import CustomLink from '../CustomLink';
import NonStretchedImage from '../NonStretchedImage';

const Store = ({ name, image }) => (
  <Box width={120} m={4}>
    <NonStretchedImage fluid={image.childImageSharp.fluid} alt={name} />
  </Box>
);

Store.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired
};

const Partners = ({ slice }) => {
  const { fields } = slice;
  const { title1 } = slice.primary;
  return (
    <FlexContainer
      as="section"
      maxWidth="1150px"
      py={3}
      mx={[3, 'auto']}
      flexDirection="column"
      alignItems="center"
    >
      {RichText.render(title1)}
      <Flex flexWrap="wrap" justifyContent="center" alignItems="center">
        {fields.map((item, i) => (
          <CustomLink
            key={shortid.generate()}
            linkType="external"
            linkURL={item.link_url}
          >
            <Store name={item.image.alt} image={item.imageSharp} />
          </CustomLink>
        ))}
      </Flex>
    </FlexContainer>
  );
};

Partners.propTypes = {
  slice: PropTypes.object.isRequired
};

export default Partners;
