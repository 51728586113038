/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Flex } from './elements';
import CustomLink from './CustomLink';

import ArrowRight from '../images/right-arrow.inline.svg';

const Link = styled(Flex)`
  position: relative;
  transition: all 0.3s ease-in-out;

  & > a,
  & > a:visited {
    color: ${props => themeGet(props.color)(props)};
    text-decoration: none;
    border-radius: 0.3rem;
    font-weight: ${({ bold }) => (bold ? '600' : 'normal')};
  }

  & g {
    fill: ${props => themeGet(props.color)(props)};
  }

  &:hover,
  &:focus {
    & > a {
      color: ${props => themeGet(props.hoverColor)(props)};
    }
  }

  & > a > svg {
    width: 20px;
    height: 13px;
    margin-left: ${themeGet('space.2')};
  }
`;

const ActionLink = ({ label, linkURL, color, hoverColor, bold, ...props }) => (
  <Link
    alignItems="center"
    color={color}
    hoverColor={hoverColor}
    bold={bold}
    {...props}
  >
    <CustomLink linkURL={linkURL} {...props}>
      {label}
      <ArrowRight />
    </CustomLink>
  </Link>
);

export default ActionLink;

ActionLink.propTypes = {
  label: PropTypes.string.isRequired,
  linkURL: PropTypes.string.isRequired,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  bold: PropTypes.bool
};

ActionLink.defaultProps = {
  color: 'colors.text',
  hoverColor: 'colors.primary',
  bold: false
};
