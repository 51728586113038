import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import {
  Hero,
  Howto,
  Benefits,
  Features,
  Embed,
  CTA,
  Image,
  TextBlock,
  TextSlice,
  PurchaseForm,
  Stores,
  Partners
} from 'gatsby-theme-10minbbq';

const PageSlices = ({ slices }) =>
  slices.map(slice => {
    switch (slice.type) {
      case 'hero':
        return <Hero key={shortid.generate()} slice={slice} />;

      case 'howto':
        return <Howto key={shortid.generate()} slice={slice} />;

      case 'image___text':
        return <Benefits key={shortid.generate()} slice={slice} />;

      case 'features':
        return <Features key={shortid.generate()} slice={slice} />;

      case 'cta':
        return <CTA key={shortid.generate()} slice={slice} />;

      case 'embed':
        return <Embed key={shortid.generate()} slice={slice} />;

      case 'image':
        return <Image key={shortid.generate()} slice={slice} />;

      case 'text_block':
        return <TextBlock key={shortid.generate()} slice={slice} />;

      case 'text':
        return <TextSlice key={shortid.generate()} slice={slice} />;

      case 'purchase_form':
        return <PurchaseForm key={shortid.generate()} slice={slice} />;

      case 'stores':
        return <Stores key={shortid.generate()} slice={slice} />;

      case 'partners':
        return <Partners key={shortid.generate()} slice={slice} />;

      default:
        return null;
    }
  });

PageSlices.propTypes = {
  slices: PropTypes.array.isRequired
};

export default PageSlices;
