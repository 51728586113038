import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { BryndanWriteTTF } from './fonts';

export default createGlobalStyle`
  ${reset}
  * { box-sizing: border-box;  }

  .grecaptcha-badge { visibility: hidden; }

  @font-face {
    font-family: "Bryndan Write";
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
    src: local("Bryndan Write"), url(${BryndanWriteTTF}) format("opentype");
  }

  body {
    margin:0;
    font-size: 18px;
    font-family: Montserrat;
    font-weight: normal;
    letter-spacing: 2px;
    color: ${props => props.theme.colors.text};
  }

  a {
    transition: all 0.3s ease-in-out;
    color: black;
    text-decoration: underline;
    &:hover,
    &:focus {
      color: ${props => props.theme.colors.primary};
    }
  }

  html {
    h1, h2, h3, h4, h5, h6 {
      font-family: "Bryndan Write";
      font-weight: ${props => props.theme.fontWeights.bold};
      color: ${props => props.theme.colors.text};
      margin-bottom: ${props => props.theme.space[4]};
    }

    h1 {
      font-size: ${props => props.theme.fontSizes[5]};
    }
    h2 {
      font-size: ${props => props.theme.fontSizes[4]};
    }
    h3 {
      font-size: ${props => props.theme.fontSizes[3]};
    }
    h4 {
      font-size: ${props => props.theme.fontSizes[2]};
    }
    h5 {
      font-size: ${props => props.theme.fontSizes[1]};
    }
    h6 {
      font-size: ${props => props.theme.fontSizes[0]};
    }
  }
`;
