import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Layout, PageSlices } from 'gatsby-theme-10minbbq';

const PageBody = ({ document }) => (
  <>
    <PageSlices slices={document.body} />
  </>
);

PageBody.propTypes = {
  document: PropTypes.object.isRequired
};

const PageTemplate = ({ data, location }) => {
  const {
    prismic: { page }
  } = data;

  const layoutNode = data.prismic.allLayouts.edges.slice(0, 1).pop();
  if (!layoutNode) return null;

  const { node: layout } = layoutNode;

  if (!page) return null;

  return (
    <Layout layout={layout} siteMetadata={data.site.siteMetadata}>
      <PageBody document={page} />
    </Layout>
  );
};

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default PageTemplate;

export const query = graphql`
  query PageBySlug($uid: String!, $locale: String!) {
    site {
      siteMetadata {
        title
        author
        languages {
          defaultLangKey
          langs
          menuLabels
        }
      }
    }
    prismic {
      allLayouts(lang: $locale) {
        edges {
          node {
            about_title
            about_description
            contacts_title
            navigation_title
            address
            email
            phone
            site_name
            header_nav_items {
              text
              link {
                ... on PRISMIC_Homepage {
                  title
                  _linkType
                  _meta {
                    type
                    lang
                  }
                }
                ... on PRISMIC_Page {
                  title
                  _linkType
                  meta_title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
            }
            footer_nav_items {
              link {
                _linkType
                ... on PRISMIC_Homepage {
                  title
                  _linkType
                  _meta {
                    type
                  }
                }
                ... on PRISMIC_Page {
                  title
                  meta_title
                  _meta {
                    uid
                    type
                  }
                }
              }
              text
            }
          }
        }
      }
      page(uid: $uid, lang: $locale) {
        title
        meta_title
        meta_description
        body {
          ... on PRISMIC_PageBodyQuote {
            type
            primary {
              quote_author
              quote_text
            }
          }
          ... on PRISMIC_PageBodyCta {
            type
            primary {
              action_label
              action_url
              banner
              heading
              subheading
            }
          }
          ... on PRISMIC_PageBodyImage___text {
            type
            primary {
              title
            }
            fields {
              heading
              image
              image_on_the_left
              subheading
            }
          }
          ... on PRISMIC_PageBodyEmbed {
            type
            primary {
              embed_link
            }
          }
          ... on PRISMIC_PageBodyText_block {
            type
            primary {
              paragraph
              title1
            }
          }
          ... on PRISMIC_PageBodyText {
            type
            primary {
              content
            }
          }
          ... on PRISMIC_PageBodyImage {
            type
            primary {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
          ... on PRISMIC_PageBodyPurchase_form {
            type
            primary {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 250, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                    presentationHeight
                  }
                }
              }
              price
              product
              heading
              quantity_label
              shipping_label
              subtotal_label
              total_label
              vat_notification
              free_shipping_notification
              name_placeholder
              phone_placeholder
              address_placeholder
              notes_placeholder
              purchase_button_label
            }
          }
          ... on PRISMIC_PageBodyStores {
            type
          }
          ... on PRISMIC_PageBodyPartners {
            type
            fields {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 120, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                    presentationHeight
                  }
                }
              }
              link_url
            }
            primary {
              title1
            }
          }
        }
      }
    }
  }
`;
