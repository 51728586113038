import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { Container, Flex, Box, Text } from '../elements';
import Logo from './Logo';
import HamburgerButton from '../HamburgerButton';
import { media } from '../../shared/util';
import linkResolver from '../../utils/linkResolver';

const Hamburger = styled(HamburgerButton)`
  display: none;
  ${media.phone`display: block;`}
`;

const Menu = styled(Flex)`
  ${media.phone`
    background: ${themeGet('colors.white')};
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
    opacity: ${({ isVisible }) => (isVisible ? 0.95 : 0)};
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
  `}
  transition: all 0.2s ease;
`;

const MenuItem = styled(Box)`
  display: inline-block;

  &:first-child {
    padding-left: 0;
  }

  & > a {
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
  }

  & > a.nav-active {
    font-weight: bold;
  }
`;

const LocaleSwitcher = () => (
  <Flex>
    <Link to="/" hrefLang="bg">
      БГ
    </Link>
    &nbsp;/&nbsp;
    <Link to="/en" hrefLang="en-us">
      EN
    </Link>
  </Flex>
);

class Header extends Component {
  state = { open: false };

  handleClick = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  render() {
    const { open } = this.state;
    const {
      data: { headerNavItems }
    } = this.props;

    return (
      <Container as="header" maxWidth="1150px" py={3} mx={[3, 'auto']}>
        <Flex
          flex={1}
          as="nav"
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex alignItems="center">
            <Box width={80} height={80}>
              <Link to="/" aria-label="10minBBQ, Back to Home">
                <Logo />
              </Link>
            </Box>
            <Menu
              as="ul"
              pl={[0, 3]}
              isVisible={open}
              flexDirection={['column', 'column', 'row']}
              justifyContent="center"
              alignItems="center"
            >
              {headerNavItems.map((item, i) => (
                <MenuItem as="li" key={`item_${i}`} pl={[0, 3]} py={[5, 0]}>
                  {item.link._linkType === 'Link.document' ? (
                    <Link
                      to={linkResolver(item.link._meta)}
                      data-testid={`navItem-${i}`}
                      test="doc"
                      activeClassName="nav-active"
                    >
                      <Text fontWeight={['bold', 'normal']} color="text">
                        {item.text}
                      </Text>
                    </Link>
                  ) : (
                    <a
                      href={item.link.url}
                      data-testid={`navItem-${i}`}
                      // activeClassName="nav-active"
                    >
                      <Text fontWeight={['bold', 'normal']} color="text">
                        {item.text}
                      </Text>
                    </a>
                  )}
                </MenuItem>
              ))}
            </Menu>
          </Flex>
          <LocaleSwitcher />
          <Hamburger
            isOpen={open}
            menuClicked={this.handleClick}
            animationDuration={0.2}
            // color={theme.colors.text}
            width={28}
            height={18}
            strokeWidth={2}
            rotate={0}
            borderRadius={1}
          />
        </Flex>
      </Container>
    );
  }
}

Header.propTypes = {
  data: PropTypes.object.isRequired
};

export default Header;
