import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import styled from 'styled-components';
import { FlexContainer, Flex, Text } from '../elements';

const FeatureWrapper = styled(Flex)`
  & > img {
    height: 70px;
  }
`;

const Feature = ({ heading, subheading, image }) => (
  <FeatureWrapper width={[1, 1 / 5]} flexDirection="column" pb={[9, 0]}>
    {/* //TODO: Update with local file after merging https://github.com/birkir/gatsby-source-prismic-graphql/pull/89 */}
    {/* <img src={item.imageSharp.publicURL} alt={item.image.alt} /> */}
    {/* <img src={image.publicURL} alt={heading} /> */}
    <img src={image.url} alt={heading} />
    <Text lineHeight={2} pt={5} textAlign={['center', 'left']}>
      {subheading}
    </Text>
  </FeatureWrapper>
);

Feature.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired
};

const Features = ({ slice }) => (
  <FlexContainer
    as="section"
    maxWidth="1150px"
    py={3}
    mx={[3, 'auto']}
    justifyContent="space-between"
    flexDirection={['column', 'row']}
  >
    {slice.fields.map(item => (
      <Feature
        key={shortid.generate()}
        heading={item.heading}
        subheading={item.subheading}
        image={item.image}
      />
    ))}
  </FlexContainer>
);

Features.propTypes = {
  slice: PropTypes.object.isRequired
};

export default Features;
