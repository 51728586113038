import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexContainer, Box } from '../elements';

const EmbedWrapper = styled(Box)`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Embed = ({ slice }) => (
  <FlexContainer
    as="section"
    maxWidth="1150px"
    py={3}
    mx={[3, 'auto']}
    justifyContent="center"
  >
    <Box width={[1, 2 / 3]}>
      <EmbedWrapper
        dangerouslySetInnerHTML={{ __html: slice.primary.embed_link.html }}
      />
    </Box>
  </FlexContainer>
);

Embed.propTypes = {
  slice: PropTypes.object.isRequired
};

export default Embed;
