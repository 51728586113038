import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { themeGet } from '@styled-system/theme-get';

import { Container, FlexContainer, Flex, Box, Text } from '../elements';
import Logo from './Logo';
import linkResolver from '../../utils/linkResolver';

const StyledContainer = styled(Container)`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.colors.text};
`;

const Menu = styled(Flex)``;

const MenuItem = styled(Box)`
  & > a {
    text-decoration: none;
    color: ${themeGet('colors.text')};
    font-size: ${themeGet('fontSizes.0')};
  }
`;

const NavLinks = ({ links }) => (
  <Menu
    as="ul"
    flexDirection="column"
    alignItems={['center', 'stretch']}
    mb={[5, 0]}
  >
    {links.map((item, i) => (
      <MenuItem as="li" key={item.link._meta.uid || '/'} mb={[1, 0]}>
        <Link
          to={linkResolver(item.link._meta)}
          data-testid={`navItem-${i}`}
          activeClassName="nav-active"
        >
          {item.text}
        </Link>
      </MenuItem>
    ))}
  </Menu>
);

NavLinks.propTypes = {
  links: PropTypes.array.isRequired
};

const Footer = ({ data }) => (
  <StyledContainer as="footer" maxWidth="none" mx={0}>
    <FlexContainer
      maxWidth="1150px"
      py={3}
      mx={[3, 'auto']}
      justifyContent="space-between"
      flexDirection={['column', 'row']}
    >
      <Flex
        alignItems={['center', 'stretch']}
        flexDirection={['column', 'row']}
        mb={[3, 0]}
      >
        <Box width={80} height={80} mb={[2, 0]}>
          <Link to="/" aria-label="10minBBQ, Back to Home">
            <Logo />
          </Link>
        </Box>
        <Box ml={[0, 3]} width={[1, 300]}>
          <Text
            as="h2"
            fontSize={2}
            fontWeight="bold"
            mb={2}
            textAlign={['center', 'left']}
          >
            {data.aboutTitle}
          </Text>
          <Text fontSize={0} lineHeight={2} textAlign={['center', 'left']}>
            {data.aboutDescription[0].text}
          </Text>
        </Box>
      </Flex>
      <Box mb={[3, 0]} width={[1, 400]}>
        <Text
          as="h2"
          fontSize={2}
          fontWeight="bold"
          mb={2}
          textAlign={['center', 'left']}
        >
          {data.contactsTitle}
        </Text>
        <Text fontSize={0} lineHeight={2} textAlign={['center', 'left']}>
          {data.address}
        </Text>
        <Text fontSize={0} lineHeight={2} textAlign={['center', 'left']}>
          {data.phone}
        </Text>
        <Text fontSize={0} lineHeight={2} textAlign={['center', 'left']}>
          {data.email}
        </Text>
      </Box>
      <Box as="nav">
        <Text
          as="h2"
          fontSize={2}
          fontWeight="bold"
          mb={2}
          textAlign={['center', 'left']}
        >
          {data.navigationTitle}
        </Text>
        <NavLinks links={data.footerNavItems} />
      </Box>
    </FlexContainer>
  </StyledContainer>
);

Footer.propTypes = {
  data: PropTypes.object.isRequired
};

export default Footer;
