import React from 'react';
import PropTypes from 'prop-types';
// import Helmet from 'react-helmet';

import Header from './Header';
import Footer from './Footer';
import CookieConsent from '../CookieConsent';

import GlobalStyle from '../../shared/css/globalStyles';

const Layout = ({ children, siteMetadata, layout }) => {
  const {
    about_title: aboutTitle,
    about_description: aboutDescription,
    contacts_title: contactsTitle,
    navigation_title: navigationTitle,
    address,
    email,
    phone,
    footer_nav_items: footerNavItems,
    header_nav_items: headerNavItems
  } = layout;

  return (
    <>
      <GlobalStyle />
      <Header data={{ headerNavItems }} />
      {children}
      <Footer
        data={{
          aboutTitle,
          aboutDescription,
          contactsTitle,
          navigationTitle,
          address,
          email,
          phone,
          footerNavItems
        }}
      />
      <CookieConsent />
      {/* <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/5344861.js"
        />
      </Helmet> */}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  siteMetadata: PropTypes.object.isRequired,
  layout: PropTypes.object.isRequired
};

export default Layout;
