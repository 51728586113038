import React from 'react';
import Helmet from 'react-helmet';

// Check gatsby-browser for init code
const CookieConsent = () => (
  <Helmet>
    <link
      rel="stylesheet"
      type="text/css"
      href="https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.css"
    />
    <script src="https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js" />
  </Helmet>
);

export default CookieConsent;
