import React from 'react';
import PropTypes from 'prop-types';
import NonStretchedImage from '../NonStretchedImage';
import { FlexContainer, Flex, Box, Text } from '../elements';
import ButtonLink from '../ButtonLink';

const Hero = ({ slice }) => {
  const {
    heading,
    subheading,
    imageSharp,
    action_label: actionLabel,
    action_url: actionURL
  } = slice.primary;
  const patternIsExternal = /^((http|https|ftp):\/\/)/;
  const isExternalURL = patternIsExternal.test(actionURL);
  return (
    <FlexContainer
      as="section"
      flexDirection={['column-reverse', 'row']}
      maxWidth="1150px"
      py={7}
      mx={[3, 'auto']}
    >
      <Flex width={[1, 1 / 2]} flexDirection="column" justifyContent="center">
        <Text as="h1" textAlign={['center', 'left']}>
          {heading}
        </Text>
        <Text lineHeight={2} textAlign={['center', 'left']}>
          {subheading}
        </Text>
        <Flex mt={5} justifyContent={['center', 'flex-start']}>
          {isExternalURL ? (
            <ButtonLink as="a" href={actionURL}>
              {actionLabel}
            </ButtonLink>
          ) : (
            <ButtonLink to={actionURL}>{actionLabel}</ButtonLink>
          )}
        </Flex>
      </Flex>
      <Box width={[1, 1 / 2]} px={[5, 0]} pb={[7, 0]}>
        <NonStretchedImage fluid={imageSharp.childImageSharp.fluid} />
      </Box>
    </FlexContainer>
  );
};

Hero.propTypes = {
  slice: PropTypes.object.isRequired
};

export default Hero;
