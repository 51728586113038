const linkResolver = function(doc) {
  if (doc.type === 'homepage') {
    return doc.lang === 'bg' ? '/' : '/en';
  }
  if (doc.type === 'blog_home') {
    return '/blog';
  }
  if (doc.type === 'post') {
    return `/blog/${doc.uid}`;
  }
  if (doc.type === 'page') {
    return doc.lang === 'bg' ? `/${doc.uid}` : `/en/${doc.uid}`;
  }
  return '/';
};

module.exports = linkResolver;
