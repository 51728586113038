module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Montserrat:600,400:latin,cyrillic"]}},
    },{
      plugin: require('/opt/build/repo/packages/gatsby-theme-10minbbq/gatsby-browser.js'),
      options: {"plugins":[],"root":"/opt/build/repo/website","i18n":true,"meta":{"siteUrl":"https://10minbbq.netlify.com","siteTitle":"10minBBQ","siteTitleAlt":"10minBBQ","siteTitleShort":"10minBBQ","siteDescription":"10minBBQ е дървено кубче, пълно със 100% натурални дървени въглища, с което за 10 минути получаваш перфектната жар за твоето барбекю.","manifest":{"themeColor":"#253688","backgroundColor":"#253688"},"googleAnalyticsId":"UA-XXXX-X","facebook":"Your optional Facebook App ID","twitter":"Your Twitter username","schema":{"author":"Radi Danchev"}}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"10minbbq"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-132070062-2"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"10minBBQ - дървено кубче, пълно с натурални дървени въглища","short_name":"10minBBQ","description":"10minBBQ е иновативен продукт, 100% натурален, който позволява да разпалите барбекю и да имате отлична жар само за 10 минути. Точно затова нарекохме нашето барбекю кубче 10minBBQ.","start_url":"","background_color":"#DC1E35","theme_color":"#DC1E35","display":"standalone","icon":"src/images/favicon.png"},
    }]
