import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import shortid from 'shortid';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';

import { FlexContainer, Flex, Box, Text } from '../elements';
import { media } from '../../shared/util';
import NonStretchedImage from '../NonStretchedImage';
import { quantityOptions, deliveryPrices } from '../../data/purchase';
import validateEmail from '../../utils/emailValidation';

const recaptchaClientKey = '6LeP_rIUAAAAAEjLF679gal43bRcsZ8B_C6K0SWJ';

const Label = styled(Text)`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
`;

const Tag = styled(Box).attrs({
  color: 'green',
  px: 2
})`
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid ${props => themeGet(props.color)(props)};
  line-height: calc(${props => themeGet('fontSizes.3')(props)} - 2px);
`;

const QuantityButton = styled(Box)`
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #000;
  color: ${({ selected, theme }) =>
    selected ? theme.colors.white : theme.colors.black};
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.black : theme.colors.white};
  cursor: pointer;
`;

export const StyledForm = styled(Flex).attrs({
  flexDirection: 'column'
})`
  & > p.error {
    color: ${themeGet('colors.primary')};
    font-size: 10px;
    margin-bottom: ${themeGet('space.3')};
  }
  & > p.error::before {
    display: inline;
    content: '⚠ ';
  }
  & > input,
  & > textarea {
    font-size: ${themeGet('fontSizes.1')};
    border: 1px solid ${themeGet('colors.grey')};
    border-radius: 0;
    -webkit-appearance: none;
    padding: ${themeGet('space.1')} ${themeGet('space.2')};
    margin-bottom: ${themeGet('space.3')};
    outline: 0;

    &:focus {
      border: 1px solid ${themeGet('colors.green')};
    }
  }

  & > input.error {
    border: 1px solid ${themeGet('colors.primary')};
    &:focus {
      border: 1px solid ${themeGet('colors.green')};
    }
  }

  & > button[type='submit'] {
    ${media.phone`
      margin-top: ${themeGet('space.3')};
    `}
    font-size: ${themeGet('fontSizes.1')};
    color: ${themeGet('colors.white')};
    background-color: ${themeGet('colors.primary')};
    border: 2px solid ${themeGet('colors.primary')};
    border-radius: 4px;
    padding: ${themeGet('space.1')} ${themeGet('space.2')};
    outline: 0;

    &:hover {
      background-color: ${themeGet('colors.green')};
      border: 2px solid ${themeGet('colors.green')};
    }

    &:disabled {
      background-color: ${themeGet('colors.grey')};
      border: 2px solid ${themeGet('colors.grey')};
    }
  }
`;

const PricingForm = ({
  product,
  quantity,
  handleQualitySelect,
  delivery,
  total,
  quantityLabel,
  shippingLabel,
  subtotalLabel,
  totalLabel,
  vatNotification,
  freeShippingNotification
}) => (
  <Box>
    <Text fontSize={3} fontWeight="bold" mb={2}>
      {product.name}
    </Text>
    <Text fontSize={3} color="grey">
      {product.price} лв.
    </Text>
    <Label mt={3} mb={4}>
      {quantityLabel}
    </Label>
    <Box>
      {[0, 1, 2, 3].map(i => (
        <QuantityButton
          key={shortid.generate()}
          mx={2}
          selected={quantity === quantityOptions[i]}
          onClick={() => handleQualitySelect(i, product.price)}
        >
          {quantityOptions[i]}
        </QuantityButton>
      ))}
    </Box>
    <Label mt={3} mb={4}>
      {shippingLabel}
    </Label>
    {delivery ? (
      <Text fontSize={3} color="grey">
        {delivery} лв.
      </Text>
    ) : (
      <Tag>{freeShippingNotification}</Tag>
    )}
    <Label mt={3} mb={4}>
      {subtotalLabel}
    </Label>
    <Text fontSize={3} color="grey" mb={4}>
      {quantity * product.price} лв.
    </Text>
    <Label mt={3} mb={4}>
      {totalLabel}
    </Label>
    <Text fontSize={3} fontWeight="bold" color="black" mb={4}>
      {total} лв.
    </Text>
    <Text fontSize={0} color="black" mb={4}>
      {vatNotification}
    </Text>
  </Box>
);

PricingForm.propTypes = {
  product: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  handleQualitySelect: PropTypes.func.isRequired,
  delivery: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  quantityLabel: PropTypes.string.isRequired,
  shippingLabel: PropTypes.string.isRequired,
  subtotalLabel: PropTypes.string.isRequired,
  totalLabel: PropTypes.string.isRequired,
  vatNotification: PropTypes.string.isRequired,
  freeShippingNotification: PropTypes.string.isRequired
};

const ShippingForm = ({
  errors,
  handleChange,
  handleChangeCheckbox,
  onSubmit,
  name,
  email,
  phone,
  address,
  notes,
  agreed,
  working,
  namePlaceholder,
  phonePlaceholder,
  addressPlaceholder,
  notesPlaceholder,
  purchaseButtonLabel
}) => (
  <StyledForm as="form" onSubmit={onSubmit}>
    <input
      type="text"
      name="name"
      onChange={handleChange}
      value={name}
      placeholder={namePlaceholder}
      className={errors && errors.name ? 'error' : null}
    />
    {errors.name && <p className="error">{errors.name}</p>}
    <input
      type="email"
      name="email"
      onChange={handleChange}
      value={email}
      placeholder="user@email.com"
      className={errors && errors.email ? 'error' : null}
    />
    {errors.email && <p className="error">{errors.email}</p>}
    <input
      type="text"
      name="phone"
      onChange={handleChange}
      value={phone}
      placeholder={phonePlaceholder}
      className={errors && errors.phone ? 'error' : null}
    />
    {errors.phone && <p className="error">{errors.phone}</p>}
    <input
      type="text"
      name="address"
      onChange={handleChange}
      value={address}
      placeholder={addressPlaceholder}
      className={errors && errors.address ? 'error' : null}
    />
    {errors.address && <p className="error">{errors.address}</p>}
    <textarea
      name="notes"
      onChange={handleChange}
      value={notes}
      placeholder={notesPlaceholder}
    />
    <Text as="label" fontSize={0} htmlFor="agree" mb={3}>
      <input
        type="checkbox"
        name="agreed"
        id="agree"
        className="agree"
        value={agreed}
        onChange={handleChangeCheckbox}
      />
      &nbsp;Съгласявам се с{' '}
      <a href="/terms" target="_blank">
        Общите Условия
      </a>{' '}
      и{' '}
      <a href="/privacy-policy" target="_blank">
        Политиката за поверителност
      </a>
      .
    </Text>
    <button type="submit" disabled={!agreed || working}>
      {purchaseButtonLabel}
    </button>
  </StyledForm>
);

ShippingForm.propTypes = {
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeCheckbox: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
  agreed: PropTypes.bool.isRequired,
  working: PropTypes.bool.isRequired,
  namePlaceholder: PropTypes.string.isRequired,
  phonePlaceholder: PropTypes.string.isRequired,
  addressPlaceholder: PropTypes.string.isRequired,
  notesPlaceholder: PropTypes.string.isRequired,
  purchaseButtonLabel: PropTypes.string.isRequired
};

const initialState = {
  success: false,
  working: false,
  quantity: 8,
  delivery: 0,
  total: 59.92,
  name: '',
  email: '',
  phone: '',
  address: '',
  notes: '',
  agreed: false,
  recaptchaToken: '',
  errors: {}
};

class PurchaseForm extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    ...initialState
  };

  async componentDidMount() {
    loadReCaptcha(recaptchaClientKey);
  }

  verifyCallback = recaptchaToken => {
    // Here you will get the final recaptchaToken!!!
    this.setState({ recaptchaToken });
  };

  handleQualitySelect = (index, price) => {
    const quantity = quantityOptions[index];
    const delivery = deliveryPrices[index];
    const total = quantity * price + delivery;
    this.setState({ quantity, delivery, total: Math.round(total * 100) / 100 });
  };

  handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value
    });
  };

  handleChangeCheckbox = e => {
    this.setState({
      [`${e.target.name}`]: e.target.checked
    });
  };

  clearForm = () => {
    this.setState({ ...initialState });
  };

  validate = data => {
    let result = true;
    const { name, email, phone, address } = data;

    const { errors } = this.state;

    if (!name || name.length === 0) {
      result = false;
      errors.name = 'Моля попълнете вашето име';
    }

    if (!validateEmail(email)) {
      result = false;
      errors.email = 'Невалиден електронен адрес';
    }

    if (!phone || phone.length === 0) {
      result = false;
      errors.phone = 'Моля попълнете вашия телефон';
    }

    if (!address || address.length === 0) {
      result = false;
      errors.address = 'Моля попълнете адрес за доставка';
    }

    this.setState({ errors });

    return result;
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      name,
      email,
      phone,
      address,
      notes,
      quantity,
      delivery,
      total,
      recaptchaToken
    } = this.state;

    const body = {
      name,
      email,
      phone,
      address,
      notes,
      quantity,
      delivery,
      total,
      recaptchaToken
    };

    if (!this.validate(body)) {
      return;
    }

    if (recaptchaToken.length > 0) {
      this.setState({ working: true });
      fetch('/.netlify/functions/purchase', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(json => {
          this.setState({ ...initialState, success: true });
        })
        .catch(error => {
          this.setState({ success: false, working: false });
          console.error(error);
        });
    }
  };

  render() {
    const { slice } = this.props;
    const {
      price: productPrice,
      product: productName,
      heading,
      quantity_label: quantityLabel,
      shipping_label: shippingLabel,
      subtotal_label: subtotalLabel,
      total_label: totalLabel,
      vat_notification: vatNotification,
      free_shipping_notification: freeShippingNotification,
      name_placeholder: namePlaceholder,
      phone_placeholder: phonePlaceholder,
      address_placeholder: addressPlaceholder,
      notes_placeholder: notesPlaceholder,
      purchase_button_label: purchaseButtonLabel,
      imageSharp
    } = slice.primary;
    const { success, working, quantity, delivery, total } = this.state;
    const { name, email, phone, address, notes, agreed, errors } = this.state;
    return (
      <FlexContainer
        as="section"
        maxWidth="1150px"
        py={3}
        mx={[3, 'auto']}
        flexDirection="column"
        alignItems="center"
      >
        <h1>{heading}</h1>
        <Flex my={9} width={1} flexGrow={1} flexDirection={['column', 'row']}>
          <Box width={[1, 1 / 3]}>
            <NonStretchedImage fluid={imageSharp.childImageSharp.fluid} />
          </Box>
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width={[1, 1 / 3]}
            mt={[7, 0]}
          >
            <PricingForm
              product={{ name: productName, price: productPrice }}
              quantity={quantity}
              handleQualitySelect={this.handleQualitySelect}
              delivery={delivery}
              total={total}
              quantityLabel={quantityLabel}
              shippingLabel={shippingLabel}
              subtotalLabel={subtotalLabel}
              totalLabel={totalLabel}
              vatNotification={vatNotification}
              freeShippingNotification={freeShippingNotification}
            />
          </Flex>
          <Box width={[1, 1 / 3]} px={[5, 0]} mt={[3, 0]}>
            {success ? (
              <h1>Thank you!</h1>
            ) : (
              <>
                <ShippingForm
                  errors={errors}
                  working={working}
                  onSubmit={this.handleSubmit}
                  handleChange={this.handleChange}
                  handleChangeCheckbox={this.handleChangeCheckbox}
                  name={name}
                  email={email}
                  phone={phone}
                  address={address}
                  notes={notes}
                  agreed={agreed}
                  namePlaceholder={namePlaceholder}
                  phonePlaceholder={phonePlaceholder}
                  addressPlaceholder={addressPlaceholder}
                  notesPlaceholder={notesPlaceholder}
                  purchaseButtonLabel={purchaseButtonLabel}
                />
                <ReCaptcha
                  sitekey={recaptchaClientKey}
                  action="purchase"
                  verifyCallback={this.verifyCallback}
                />
              </>
            )}
          </Box>
        </Flex>
      </FlexContainer>
    );
  }
}

PurchaseForm.propTypes = {
  slice: PropTypes.object.isRequired
};

export default PurchaseForm;
