import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Layout } from 'gatsby-theme-10minbbq';

const NotFoundPage = ({ data: { site, prismic } }) => {
  const layoutNode = prismic.allLayouts.edges.slice(0, 1).pop();
  if (!layoutNode) return null;

  const { node: layout } = layoutNode;

  return (
    <Layout layout={layout} siteMetadata={site.siteMetadata}>
      <h1>404 Page Not Found</h1>
    </Layout>
  );
};

NotFoundPage.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object.isRequired,
    prismic: PropTypes.object.isRequired
  }).isRequired
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        languages {
          defaultLangKey
          langs
          menuLabels
        }
      }
    }
    prismic {
      allLayouts(lang: "bg") {
        edges {
          node {
            about_title
            about_description
            contacts_title
            navigation_title
            address
            email
            phone
            site_name
            header_nav_items {
              text
              link {
                ... on PRISMIC_Homepage {
                  title
                  _linkType
                  _meta {
                    type
                    lang
                  }
                }
                ... on PRISMIC_Page {
                  title
                  meta_title
                  _meta {
                    uid
                    type
                    lang
                  }
                }
              }
            }
            footer_nav_items {
              link {
                _linkType
                ... on PRISMIC_Homepage {
                  title
                  _linkType
                  _meta {
                    type
                  }
                }
                ... on PRISMIC_Page {
                  title
                  meta_title
                  _meta {
                    uid
                    type
                  }
                }
              }
              text
            }
          }
        }
      }
    }
  }
`;
