export default {
  primary: '#DC1E35',
  primaryShade: 'rgba(221, 34, 59, 0.5)',
  text: '#523629',
  white: '#FFFFFF',
  black: '#000000',
  grey: '#AAA',
  lightGrey: '#DDD',
  green: '#43a047'
};
