import styled, { css } from 'styled-components';

import {
  space,
  width,
  color,
  fontSize,
  fontWeight,
  lineHeight,
  flex,
  alignSelf,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  textAlign,
  order,
  display,
  maxWidth,
  minWidth,
  height,
  maxHeight,
  minHeight,
  size
} from 'styled-system';

export const Box = styled.div(
  {
    boxSizing: 'border-box'
  },
  space,
  color,
  width,
  fontSize,
  flex,
  order,
  alignSelf
);

Box.displayName = 'Box';

Box.propTypes = {
  ...space.propTypes,
  ...color.propTypes,
  ...width.propTypes,
  ...fontSize.propTypes,
  ...flex.propTypes
};

export const Flex = styled(Box)(
  {
    display: 'flex'
  },
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent
);

Flex.displayName = 'Flex';

Flex.propTypes = {
  ...flexWrap.propTypes,
  ...flexDirection.propTypes,
  ...alignItems.propTypes,
  ...justifyContent.propTypes
};

export const Text = styled.div`
  ${space}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${color}
  ${textAlign}
`;

Text.displayName = 'Text';

Text.propTypes = {
  ...space.propTypes,
  ...fontSize.propTypes,
  ...fontWeight.propTypes,
  ...lineHeight.propTypes,
  ...color.propTypes,
  ...textAlign.propTypes
};

const containerCss = css`
  ${display}
  ${maxWidth}
  ${minWidth}
  ${height}
  ${maxHeight}
  ${minHeight}
  ${size}
  ${space}
`;

export const Container = styled(Box)`
  ${containerCss}
`;

Container.propTypes = {
  ...display.propTypes,
  ...maxWidth.propTypes,
  ...minWidth.propTypes,
  ...height.propTypes,
  ...maxHeight.propTypes,
  ...minHeight.propTypes,
  ...size.propTypes,
  ...space.propTypes
};

Container.defaultProps = {
  mx: 'auto',
  maxWidth: '1440px'
};

export const FlexContainer = styled(Flex)`
  ${containerCss}
`;

FlexContainer.propTypes = Container.propTypes;
FlexContainer.defaultProps = Container.defaultProps;
