import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import shortid from 'shortid';
import { FlexContainer, Box, Text } from '../elements';

const FullWidthContainer = styled(FlexContainer).attrs({
  flexDirection: 'column',
  as: 'section',
  maxWidth: 'none',
  my: 5,
  mx: [0, 'auto']
})``;

const Benefits = ({ slice }) => {
  const { fields } = slice;
  return fields.map((item, i) => (
    <FullWidthContainer
      key={shortid.generate()}
      bg={i % 2 === 0 ? 'transparent' : '#efefef'}
      px={[3, 0]}
    >
      <FlexContainer
        flexDirection={['column', i % 2 === 0 ? 'row' : 'row-reverse']}
        alignItems="center"
        maxWidth={1150}
        my={9}
      >
        <Box
          width={[1, 1 / 2]}
          pr={[0, i % 2 === 0 ? 9 : 0]}
          pl={[0, i % 2 === 0 ? 0 : 9]}
          pb={[5, 0]}
        >
          {/* <NonStretchedImage fluid={item.image.childImageSharp.fluid} /> */}
          {/* //TODO: Update with local file after merging https://github.com/birkir/gatsby-source-prismic-graphql/pull/89 */}
          {/* <img src={item.imageSharp.publicURL} alt={item.image.alt} /> */}
          <img src={item.image.url} alt={item.image.alt} />
        </Box>
        <Box width={[1, 1 / 2]}>
          <Text as="h1" textAlign={['center', 'left']}>
            {item.heading}
          </Text>
          <Text lineHeight={2} textAlign={['center', 'left']}>
            {item.subheading}
          </Text>
        </Box>
      </FlexContainer>
    </FullWidthContainer>
  ));
};

Benefits.propTypes = {
  slice: PropTypes.object.isRequired
};

export default Benefits;
