import { css } from 'styled-components';
import theme from '../../tokens/theme';

export const media = Object.keys(theme.bpAliases).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${theme.bpAliases[label]}) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
