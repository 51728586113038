import React from 'react';
import PropTypes from 'prop-types';

import { FlexContainer, Box } from '../elements';
import NonStretchedImage from '../NonStretchedImage';

const Image = ({ slice }) => {
  const { imageSharp } = slice.primary;
  return (
    <FlexContainer
      as="section"
      maxWidth="1150px"
      py={3}
      mx={[3, 'auto']}
      justifyContent="center"
    >
      <Box width={[1, 1 / 2]} px={[5, 0]} pb={[7, 0]}>
        <NonStretchedImage fluid={imageSharp.childImageSharp.fluid} />
      </Box>
    </FlexContainer>
  );
};

Image.propTypes = {
  slice: PropTypes.object.isRequired
};

export default Image;
