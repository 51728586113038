import { Link } from 'gatsby';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const ButtonLink = styled(Link)`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 1rem;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0.3rem;
  text-decoration: none;
  display: table;
  transition: 0.3s;
  &:hover {
    background-color: ${props => themeGet('colors.text')(props)};
    color: ${({ theme }) => theme.colors.white};
    transition: 0.3s;
  }
`;

export default ButtonLink;
