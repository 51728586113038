import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Layout, PageSlices } from 'gatsby-theme-10minbbq';

const PageBody = ({ document }) => (
  <>
    <PageSlices slices={document.body} />
  </>
);

PageBody.propTypes = {
  document: PropTypes.object.isRequired
};

const HomePage = ({ data, location }) => {
  const layoutNode = data.prismic.allLayouts.edges.slice(0, 1).pop();
  if (!layoutNode) return null;

  const { node: layout } = layoutNode;

  const doc = data.prismic.allHomepages.edges.slice(0, 1).pop();

  if (!doc) return null;

  return (
    <Layout layout={layout} siteMetadata={data.site.siteMetadata}>
      <PageBody document={doc.node} />
    </Layout>
  );
};

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default HomePage;

export const data = graphql`
  query IndexQuery($locale: String!) {
    site {
      siteMetadata {
        title
        author
        languages {
          defaultLangKey
          langs
          menuLabels
        }
      }
    }
    prismic {
      allLayouts(lang: $locale) {
        edges {
          node {
            about_title
            about_description
            contacts_title
            navigation_title
            address
            email
            phone
            site_name
            header_nav_items {
              text
              link {
                ... on PRISMIC_Homepage {
                  title
                  _linkType
                  _meta {
                    type
                    lang
                  }
                }
                ... on PRISMIC_Page {
                  title
                  meta_title
                  _linkType
                  _meta {
                    uid
                    type
                    lang
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
            }
            footer_nav_items {
              link {
                _linkType
                ... on PRISMIC_Homepage {
                  title
                  _linkType
                  _meta {
                    type
                  }
                }
                ... on PRISMIC_Page {
                  title
                  meta_title
                  _meta {
                    uid
                    type
                  }
                }
              }
              text
            }
          }
        }
      }
      allHomepages(lang: $locale) {
        edges {
          node {
            title
            __typename
            body {
              ... on PRISMIC_HomepageBodyHero {
                type
                primary {
                  action_label
                  action_url
                  heading
                  subheading
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 400, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        presentationWidth
                        presentationHeight
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_HomepageBodyHowto {
                type
                primary {
                  action_label
                  action_url
                  heading
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        presentationWidth
                        presentationHeight
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_HomepageBodyImage___text {
                type
                primary {
                  title
                }
                fields {
                  heading
                  image
                  imageSharp {
                    publicURL
                  }
                  subheading
                }
              }
              ... on PRISMIC_HomepageBodyCta {
                type
                primary {
                  action_label
                  action_url
                  heading
                  subheading
                }
              }
              ... on PRISMIC_HomepageBodyFeatures {
                type
                fields {
                  heading
                  subheading
                  image
                  imageSharp {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
